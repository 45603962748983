
import Footer from "../Basic/footer";
import Navbar from "../Basic/navbar";
import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// const items = [
//   {
//     question: "What harsh truths do you prefer to ignore?",
//     answer:
//       "Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.",
//   },
//   {
//     question: "What is Calmosis, and what type of products do you offer?",
//     answer:
//       "Calmosis is a company that offers a wide range of products that help you relax and feel better. We offer everything from essential oils to bath bombs, and we are always looking for new ways to help our customers.",
//   },
//   {
//     question: "Is free will real or just an illusion?",
//     answer:
//       "In ad velit in ex nostrud dolore cupidatat consectetur ea in ut nostrud velit in irure cillum tempor laboris sed adipisicing eu esse duis nulla non.",
//   },
// ];

const items = [
  {
    question:
      "Is it legal to consume products with the Cannabis leaf Extract in India?",
    answer:
      "As per government regulations, medicines containing any schedule E (1) ingredient such as the Cannabis Sativa leaf can be legally taken under medical supervision. All products meant for oral consumption can only be purchased against a valid prescription issued by a registered medical practitioner.",
  },
  {
    question: "Can we travel with Calmosis products?",
    answer:
      "Yes, it is possible to travel with Calmosis products, but only with a valid medical prescription. It is important to note that laws and regulations regarding cannabis vary by country and state, so it's essential to research and understand the specific rules and regulations of the destination you are traveling to beforehand.",
  },
  {
    question:
      "Can individuals with certain health conditions safely use cannabis extract?",
    answer:
      "Not recommended for: Individuals under 18,Pregnant or breastfeeding women,Those with heart conditions,Individuals with diabetes",
  },
  {
    question: "What conditions can medical cannabis extract potentially treat?",
    answer:
      "Medical cannabis extract has shown promising potential in alleviating symptoms associated with chronic pain, epilepsy, anxiety, sleep disorders, stress relief, and various other medical conditions.",
  },
  {
    question: "How do I consume medical cannabis extract?",
    answer:
      " medical cannabis extract can be consumed sublingually, allowing for absorption under the tongue.",
  },
  {
    question:
      "Can I drive or operate machinery after using medical cannabis extract?",
    answer:
      "It's strongly advisable to avoid driving or operating machinery as it may impair coordination.",
  },
  {
    question:
      "What are the potential side effects of medical cannabis extract?",
    answer:
      "Possible side effects include drowsiness, dry mouth, and changes in appetite. Consult your healthcare provider in India for guidance.",
  },
  {
    question: "Is medical cannabis extract addictive?",
    answer:
      "Medical cannabis extract is generally not considered physically addictive when used responsibly and in accordance with the recommended dosage provided by a healthcare professional. However, individuals should be aware that some may develop a psychological dependence. It is important to follow medical guidance closely to minimize any potential risks associated with its use",
  },
  {
    question:
      "How long does it take for medical cannabis extract to take effect?",
    answer:
      "The onset of effects from medical cannabis extract can vary among individuals due to differences in metabolism. Generally, it is expected to show effects within approximately 60 to 90 minutes after consumption. It's important for individuals to be patient and allow sufficient time for the effects to manifest before considering additional doses.",
  },
  {
    question:
      "Can I use medical cannabis extract if I'm pregnant or breastfeeding?",
    answer:
      "It's strongly advised to avoid medical cannabis during pregnancy and breastfeeding  due to potential effects on the developing fetus or infant.",
  },
  {
    question:
      "How does medical cannabis extract interact with other medications?",
    answer:
      "Consult your healthcare provider or our in-house doctor to assess potential interactions with other medications.",
  },
  {
    question:
      "Is a prescription required for medical cannabis extract in India?",
    answer:
      "Yes, a prescription is required for legal acquisition of medical cannabis extract in India.",
  },
  {
    question:
      "How can I discuss medical cannabis with my healthcare provider ?",
    answer:
      "Openly communicate with your healthcare provider or our in-house doctor about your symptoms and desire to explore medical cannabis",
  },
  {
    question:
      "How does medical cannabis extract interact with other medications?",
    answer:
      "Consult your healthcare provider or our in-house doctor to assess potential interactions with other medications.",
  },
];

const FAQ = () => {
  return (
    <main className="faq">
      <Navbar />

      <div className="content">
        <h2 className="out">Frequently Asked Questions</h2>

        <Accordion allowZeroExpanded>
          {/* map with data */}
          {items.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h3>{item.question}</h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{item.answer}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>

      <div className="line" />
      <Footer />
    </main>
  );
};

export default FAQ;

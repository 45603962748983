import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RiAccountCircleLine } from "react-icons/ri";
import { BsCartCheck } from "react-icons/bs";
import { BsPrescription2 } from "react-icons/bs";
import { LiaAddressBookSolid } from "react-icons/lia";
const UserMenu = () => {
  const location = useLocation();

  return (
    <main className="dashboard account " data-scroll-container id="home">
      <div
        className="sidebar"
        data-scroll
        data-scroll-sticky
        data-scroll-target="#home"
      >
        <div className="logo">
          <img src="/black_logo.svg" />
        </div>
        <ul className="dashmob">
          <li className={location.pathname === "/dashboard/user" ? "active" : ""}>
            <NavLink to="/dashboard/user">
              <img src="/account.svg" alt="account" />
              {location.pathname === "/dashboard/user" && <p> Account</p>}
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/dashboard/user/orders" ? "active" : ""
            }
          >
            <NavLink to="/dashboard/user/orders">
              <img src="/order.svg" alt="orders" />
              {location.pathname === "/dashboard/user/orders" && <p> Orders</p>}
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/dashboard/user/prescription" ? "active" : ""
            }
          >
            <NavLink to="/dashboard/user/prescription">
              <img src="/prescription.svg" alt="prescription" />
              {location.pathname === "/dashboard/user/prescription" && (
                <p> Prescription</p>
              )}
            </NavLink>
          </li>
          <li
            className={
              location.pathname === "/dashboard/user/address" ? "active" : ""
            }
          >
            <NavLink to="/dashboard/user/address">
              <img src="/location.svg" alt="addresses" />
              {location.pathname === "/dashboard/user/addresses" && (
                <p> Addresses</p>
              )}
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/dashboard/user/payment">as</NavLink>
          </li> */}
        </ul>
        <ul>
          <NavLink to="/dashboard/user">
            <li
              className={
                location.pathname === "/dashboard/user" ? "active" : ""
              }
            >
              Account
            </li>
          </NavLink>
          <NavLink to="/dashboard/user/orders">
            <li
              className={
                location.pathname === "/dashboard/user/orders" ? "active" : ""
              }
            >
              Orders
            </li>
          </NavLink>

          <NavLink to="/dashboard/user/prescription">
            <li
              className={
                location.pathname === "/dashboard/user/prescription"
                  ? "active"
                  : ""
              }
            >
              Prescription
            </li>
          </NavLink>
          <NavLink to="/dashboard/user/address">
            <li
              className={
                location.pathname === "/dashboard/user/address" ? "active" : ""
              }
            >
              Addresses
            </li>
          </NavLink>
          {/* <li>
            <NavLink to="/dashboard/user/payment">Payment Details</NavLink>
          </li> */}
        </ul>
      </div>
    </main>
  );
};

export default UserMenu;

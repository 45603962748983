import React from "react";
import { NavLink } from "react-router-dom";

const DoctorMenu = () => {
  return (
    <main className="dashboard account" data-scroll-container id="home">
      <div className="sidebar" data-scroll data-scroll-sticky data-scroll-target="#home">
        <div className="logo">
          <img src="/black_logo.svg" alt="" />
        </div>

        <ul>
          <NavLink to="/dashboard/doctor/">
            <li>Your Details</li>
          </NavLink>
          <NavLink to="/dashboard/doctor/addprescription">
            <li>Add Prescription</li>
          </NavLink>

          {/* <li>
            <NavLink to="/dashboard/doctor/allprescription">All Prescription</NavLink>
          </li> */}
        </ul>
      </div>
    </main>
  );
};

export default DoctorMenu;

import React, { useEffect, useState } from "react";
import downloadIcon from "../../assets/download.svg";
import AdminMenu from "../../components/Layout/AdminMenu";
import BasicTable from "../../components/Layout/BasicTable";
import DashNav from "../../components/Layout/dash-nav";
import { useAuth } from "../../context/auth";
import axios, { baseURL } from "../../utils/axios";

function stringToArrayBuffer(byteString) {
  var byteArray = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.codePointAt(i);
  }
  return byteArray;
}

const AllOrders = () => {
  const [auth, _] = useAuth();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const headers = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 160,
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "Order #",
      width: 120,
      headerAlign: "center",
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "doctorApproval",
      headerName: "Doctor Approval",
      width: 240,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell({ value }) {
        const [approval, prescription, doctorName] = value;
        const downloadPrescription = () => {
          const el = document.createElement("a");
          const blob = new Blob([stringToArrayBuffer(prescription)], { type: "application/pdf" });
          el.href = URL.createObjectURL(blob);
          el.download = "Prescription.pdf";
          document.body.appendChild(el);
          el.click();
          URL.revokeObjectURL(el.href);
          el.remove();
        };
        return (
          <div className="flex items-start gap-1">
            {approval === "SUCCESS" ? (doctorName ? `Approved by Dr. ${doctorName}` : "APPROVED") : approval}
            {prescription && <img title="Download Prescription" className="cursor-pointer" src={downloadIcon} alt="" onClick={downloadPrescription} />}
          </div>
        );
      },
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 240,
      valueGetter: (params) => `${params.row.username || ""} | ${params.row.number.slice(3) || ""}`,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "product",
      headerName: "Product",
      width: 160,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: ({ value }) => (
        <div className="flex flex-col">
          {value.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      width: 550,
      sortable: false,
    },
  ];

  //getall products
  const getAllOrders = async () => {
    try {
      const res = await axios.get(`${baseURL}/api/admin/orders`);

      const response = await res?.data;
      const data = response;
      for (let i = 0; i < data.length; i++) {
        data[i].createdAt = new Date(data[i].createdAt).toLocaleDateString();
        data[i].paymentStatus = data[i].paymentStatus.toUpperCase();
        data[i].doctorApproval = [data[i].approval.toUpperCase(), data[i].prescription, data[i].doctorAssigned?.name];
        data[i].orderStatus = data[i].orderStatus?.toUpperCase() || "PENDING";
        data[i].id = data[i].orderId;
        let add = Object.values(data[i]?.address);
        data[i].product = data[i].lineItems.map((item) => item.name + "*" + item.quantity);
        data[i].address = Object.keys(data[i]?.address)
          .map((name, i) => `${name}: ${add[i]}`)
          .join("\n");
        setOrders(data);
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <main className="dashboard account " data-scroll-container id="home">
      <div className="row">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div class="mainbar">
          <DashNav />
          <div className="content" style={{ padding: "0" }}>
            <div className="heading ">
              <h1 className="text-center" style={{ margin: "0" }}>
                Your Orders
              </h1>
            </div>
          </div>

          <break />

          <BasicTable headers={headers} data={orders} />
        </div>
      </div>
    </main>
  );
};

export default AllOrders;

import { useNavigate } from "react-router-dom";
import UserMenu from "../../components/Layout/UserMenu";
import DashNav from "../../components/Layout/dash-nav";

import BasicTable from "../../components/Layout/BasicTable";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import axios, { baseURL } from "../../utils/axios";
import { Button } from "antd";
const DashboardOrders = () => {
  const [auth] = useAuth();
  const [orders, setOrders] = useState([]);
  // const [ordersMobView, setOrdersMobView] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const handleResize = () => setIsMobile(window.innerWidth <= 900);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const mobileHeaders = [
    {
      field: "completeInfo",
      headerName: "Details",
      width: 450,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <TextField
            sx={{
              "& fieldset": {
                border: "none",
              },
              "& textarea": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontFamily: "Comfortaa",
                fontSize: "14px",
                color: "white",
              },
              display: "block",
              width: "100%",
            }}
            multiline
            value={params.row.completeInfo.join("\n")}
            InputProps={{ readOnly: true, style: { width: "100%" } }}
          />
          <hr />
          {params.row.orderId.trackable ? (
            <Button type="link" href={`/dashboard/user/orders/${params.row.orderId.orderId}`}>
              Track Shipment
            </Button>
          ) : (
            <span>Awaiting Approval</span>
          )}
        </>
      ),
    },
  ];
  const headers = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 240,
      headerAlign: "center",
      align: "center",
    },

    // {
    //   field: "id",
    //   headerName: "Order #",
    //   width: 320,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "product",
      headerName: "Product",
      width: 360,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <TextField
            sx={{
              "& fieldset": {
                border: "none",
              },
              "& textarea": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontFamily: "Comfortaa",
                fontSize: "14px",
                color: "white",
              },
            }}
            multiline
            value={params.row.product}
            InputProps={{ readOnly: true }}
          />
        </div>
      ),
    },
    // {
    //   field: "totalQty",
    //   headerName: "Items",
    //   width: 160,
    //   headerAlign: "center",
    //   align: "center",
    //   type: "number",
    // },
    {
      field: "totalPrice",
      headerName: "Total Cost",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "orderId",
      headerName: "Track Order",
      headerAlign: "center",
      align: "center",
      type: "string",
      width: 164,
      sortable: false,
      renderCell({ value }) {
        return value.trackable ? (
          <Button type="link" href={`/dashboard/user/orders/${value.orderId}`}>
            Track Shipment
          </Button>
        ) : (
          <span>Awaiting Approval</span>
        );
      },
    },
  ];

  useEffect(() => {

    //getall products
    const getAllOrders = async () => {
      try {
        const { data } = await axios(`${baseURL}/api/orders?userId=${auth?.user?._id}`);
        for (let i = 0; i < data.length; i++) {
          data[i].createdAt = new Date(data[i].createdAt).toLocaleDateString();
          data[i].paymentStatus = data[i].paymentStatus.toUpperCase();
          data[i].id = data[i]._id;
          data[i].product = "";
          data[i].totalQty = 0;
          data[i].lineItems.forEach((item) => {
            data[i].product += item.name + " × " + item.quantity + "\n";
            data[i].totalQty += item.quantity;
          });
          data[i].product = data[i].product.slice(0, -1);
          data[i].totalPrice = `₹ ${data[i].price}`;
          data[i].completeInfo = [`Order date: ${data[i].createdAt}`, `Payment status: ${data[i].paymentStatus}`, `Products: ${data[i].product}`, `Final price: ${data[i].totalPrice}`];
          data[i].orderId = { orderId: data[i]._id, trackable: !!data[i]?.shiprocketOrderId };
          // console.log(data[i].product);
          // data[i].completeInfo = `${data[i].createdAt}\n${data[i].paymentStatus}\n${data[i].product}\n${data[i].totalPrice}`;
        }
        setOrders(data);
        // setOrdersMobView();
      } catch (error) {
        console.error("Error fetching orders data:", error);
      } finally {
        setLoading(false);
      }
    };

    getAllOrders();
  }, [auth?.user?._id]);

  const getRowHeight = (params) => {
    const lineHeight = 24;
    const numberOfLines = 1 + params.model.product.split("\n").length;
    return lineHeight * numberOfLines > 52 ? lineHeight * numberOfLines : 52;
  };

  const getRowHeightMobile = () => {
    const lineHeight = 24;
    const numberOfLines = 2 + orders[0]?.completeInfo?.split("\n")?.length || 0;
    console.log({
      orders,
      l: orders[0]?.completeInfo?.split("\n")?.length,
      numberOfLines,
      height: lineHeight * numberOfLines > 52 ? lineHeight * numberOfLines : 52,
    });
    return lineHeight * numberOfLines > 52 ? lineHeight * numberOfLines : 52;
  };

  const handleCancelClick = () => {
    navigate("/");
  };

  return (
    <main className="dashboard order" data-scroll-container id="home">
      <UserMenu />
      <div className="mainbar">
        <DashNav />
        <div className="line" />

        <div className="label">
          <div className="element-terry-francine center-itemsss">
            <p className="text-[30px]">Your Orders</p>
            <p className="text-[12px] opacity-50">View your order history or check the status of a recent order.</p>
          </div>
          <div>
            <button className="solid-btn !mt-0 !mr-[50px] !hidden md:!flex" onClick={handleCancelClick}>
              Continue shopping
            </button>
          </div>
        </div>
        <div className="line !w-[75%]  !ml-[50px] !mt-[8px] " />
        <div className="label !w-[100%] lg:!w-[90%]  lg:mx-[50px]">
          {orders.length >= 1 ? (
            <BasicTable headers={isMobile ? mobileHeaders : headers} data={orders} isMobile={isMobile} rowHeight={isMobile ? 110 : 52} />
          ) : (
            <div className="w-full h-72 flex items-center flex-col  justify-center ">
              <p className=" text-white">{loading ? `Loading...` : `You haven’t placed any orders yet.`}</p>
              <button className="solid-btn   md:!hidden" onClick={handleCancelClick}>
                Continue shopping
              </button>
            </div>
          )}
        </div>

        <div />
      </div>
    </main>
  );
};

export default DashboardOrders;

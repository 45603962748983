import React, { useState, useEffect } from "react";
import AdminMenu from "../../components/Layout/AdminMenu";
import axios from "../../utils/axios";
import toast from "react-hot-toast";
import DashNav from "../../components/Layout/dash-nav";
import BasicTable from "../../components/Layout/BasicTable";
import { useAuth } from "../../context/auth";

const AllUsers = () => {
  const [auth, _] = useAuth();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const headers = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 160,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
  ];

  //getall products
  const getAllUsers = async () => {
    try {
      const res = await axios.post("/admin/getAllUsers", { role: 0 });

      const response = await res?.data;

      if (response?.success) {
        const data = response.data;
        for (let i = 0; i < data.length; i++) {
          data[i].createdAt = new Date(data[i].createdAt).toLocaleDateString();
          data[i].name = data[i].name.toUpperCase();
          data[i].id = data[i]._id;
          data[i].email = data[i].email;
          data[i].phone = data[i].phoneNumber;
        }

        setUsers(data);
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <main className="dashboard account " data-scroll-container id="home">
      <div className="row">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div class="mainbar">
          <DashNav />
          <div className="content" style={{ padding: "0" }}>
            <div className="heading ">
              <h1 className="text-center" style={{ margin: "0" }}>
                All Users
              </h1>
            </div>
          </div>

          <break />

          <BasicTable headers={headers} data={users} />
        </div>
      </div>
    </main>
  );
};

export default AllUsers;

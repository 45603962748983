import React, { useEffect } from "react";

const Modal = ({ active, setActive, children }) => {
  useEffect(() => {
    const handler = (e) => {
      if (e.code === "Escape") {
        setActive(undefined);
      }
    };

    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [setActive]);

  return active ? (
    <div className="fixed top-0 left-0 w-full h-full z-10 backdrop-blur-sm bg-black bg-opacity-25 text-white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="32"
        height="32"
        className="absolute top-4 right-4 x-20 cursor-pointer"
        onClick={() => setActive(undefined)}
      >
        <path
          d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
          fill="rgba(255,255,255,1)"
        />
      </svg>
      <div className="w-4/5 md:w-1/2 max-h-[80%] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 overflow-auto bg-olive-green shadow-lg rounded-sm p-4">{children}</div>
    </div>
  ) : null;
};

export default Modal;

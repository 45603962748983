import React, { useEffect } from "react";

const Facebook = () => {
  useEffect(() => {
    (function () {
      window.fbq = function () {
        window.fbq.callMethod
          ? window.fbq.callMethod.apply(window.fbq, arguments)
          : window.fbq.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = window.fbq;
      window.fbq.push = window.fbq;
      window.fbq.loaded = !0;
      window.fbq.version = "2.0";
      window.fbq.queue = [];
      const t = document.createElement("script");
      t.async = !0;
      t.src = "https://connect.facebook.net/en_US/fbevents.js";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(t, s);
      window.fbq("init", "892613461478544");
      window.fbq("track", "PageView");
    })();
  }, []);

  return null;
};

export default Facebook;

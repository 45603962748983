import Footer from "../../Basic/footer";
import Navbar from "../../Basic/navbar";
import React from "react";

const Shipping = () => {
  return (
    <main className="block">
      <Navbar />
      <div className="content">
        <h2 className="out">Shipping </h2>

        <div className="one">
          <h2>Shipping Policy</h2>
          <p>
            <span>
              The delivery timeframe is contingent on your location and the
              selected shipping method, typically ranging between 5-7 days.
              Please be aware that unexpected factors, including customs
              processing, could impact delivery times. For preorders, the
              anticipated delivery period is set at 30-35 days.
            </span>
          </p>
        </div>

        {/* <div className="one">
          <h2>Return & Exchange Policy</h2>
          <p>
            <span>
              At Calmosis, we are committed to ensuring the quality of our
              products. In the event that you encounter any issues with the
              received items, please notify us within 5 days of receipt,
              providing proof of purchase and relevant details. Our customer
              support team at calm@calmosis.com will guide you through the
              return process. Upon receiving the returned item, we will promptly
              process a refund or send you a replacement, based on your
              preference. <br />
              Please note that returns or exchanges initiated beyond the
              specified notification period may not be accepted. We appreciate
              your understanding and aim to make your wellness experience with
              us as seamless as possible.
            </span>
          </p>
        </div> */}
      </div>

      <div className="line" />

      <Footer />
    </main>
  );
};

export default Shipping;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import DashNav from "../../components/Layout/dash-nav";
import UserMenu from "../../components/Layout/UserMenu";
import { Button } from "antd";
import dayjs from "dayjs";
import truckIcon from "../../assets/truck.svg";
import waitingIcon from "../../assets/waiting.svg";
import cancelledIcon from "../../assets/cancelled.svg";

const imageForproduct = {
  "peace-mantra": "/peace_shop.png",
  "sleep-mantra": "/sleep_shop.png",
};

const messageForStatus = {
  DLVD: "Delivered",
  OFD: "Out for delivery",
  RAD: "Reached at destination",
  IT: "On it's way to destination",
  PKD: "Order is being packaged",
  PUD: "Order is getting prepared",
  OFP: "Order is out for pickup",
  DRC: "Order placed",
};

const TrackShipment = () => {
  /** @type {{ orderId: string }} */
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState();
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (!orderId) return;

    const getTrackingInfo = async () => {
      try {
        const { data } = await axios.get(`/api/tracking-info/${orderId}`);
        setOrderData(data);
      } catch (error) {
        console.error("getTrackingInfo:", error);
        toast.error("Failed getting tracking info. Please try again!");
      } finally {
        setDataLoading(false);
      }
    };

    getTrackingInfo();
  }, [orderId]);

  return (
    <main className="dashboard account" data-scroll-container id="home">
      <UserMenu />

      <div className="mainbar">
        <DashNav />

        <div className="line" />

        <div className="mx-14 my-7 text-white">
          {dataLoading ? (
            <h1>Loading...</h1>
          ) : (
            <div className="font-xl">
              <h1 className="text-3xl">Order Contents</h1>

              <div className="flex gap-4 my-6">
                {orderData?.products.map((product, i) => (
                  <>
                    <div key={product.sku} className={`flex flex-col ${i ? "border-l-2 border-white pl-4" : ""}`}>
                      <img width={150} height={150} src={imageForproduct[product.sku]} alt="" />
                      <span className="text-xl">{product.name}</span>
                      <span className="text-sm">Quantity: {product.quantity}</span>
                      <span className="text-sm">
                        Price: ₹{product.price} x {product.quantity} = {product.net_total}
                      </span>
                    </div>
                  </>
                ))}
              </div>

              <hr className="my-2" />

              <span className="font-bold">Total Price: ₹{orderData.total}</span>

              <hr className="my-2" />

              <h2 className="text-2xl my-6">Current Status</h2>

              {!orderData.awb_data.awb ? (
                orderData.status !== "CANCELED" ? (
                  <div className="flex items-center gap-2 my-6">
                    <img src={waitingIcon} alt="" />
                    <span className="font-bold text-lg">Shipment yet to start</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-2 my-6">
                    <img src={cancelledIcon} alt="" />
                    <span className="font-bold text-lg">Shipment cancelled</span>
                  </div>
                )
              ) : (
                <div className="flex items-center gap-2 my-6">
                  <img src={truckIcon} alt="" />
                  <span className="font-bold text-lg">Should reach at your location by {dayjs(orderData.tracking_info?.etd).format("DD MMM, hh:mm A")}</span>
                </div>
              )}

              {orderData.tracking_info?.activities?.length ? (
                <div className="flex flex-col gap-2 my-4">
                  {orderData.tracking_info?.activities?.map((info, key) => (
                    <div key={key} className="flex gap-2 items-center">
                      {!key ? "\u3024" : key === orderData.tracking_info?.activities.length - 1 ? "\u3024" : "\u3024"}

                      <div className="flex flex-col">
                        <span>{messageForStatus[info.status]}</span>
                        <span className="text-xs">{dayjs(info.date).format("DD MMM, hh:mm A")}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              <hr className="my-6" />

              <Button type="link" href={orderData.shipments.invoice_link} download className="text-white text-xs font-bold w-full">
                Download Invoice
              </Button>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default TrackShipment;

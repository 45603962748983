import Footer from "../../Basic/footer";
import Navbar from "../../Basic/navbar";
import React from "react";

const Privacy = () => {
  return (
    <main className="block">
      <Navbar />
      <div className="content">
        <div className="one">
          <p></p>
          <h2>Privacy Policy</h2>
          <p>
            <span>
              This Privacy Policy outlines how we collect, use, and safeguard
              your personal information. We are committed to protecting your
              privacy and ensuring the security of your data. If you have any
              questions, please contact us at{" "}
              <a href="mailto:calm@calmosis.com">calm@calmosis.com</a>.
            </span>
            <span>
              {" "}
              <b>1. Information We Collect</b>
            </span>
            <span>
              1.1 Personal Information: To provide you with our Ayurvedic
              cannabis products, we may collect personal information such as
              your name, contact details, medical history (if relevant), and
              payment information. This information is collected with your
              explicit consent.
              <br />
            </span>
            <span>
              1.2 Medical Information: If you choose to provide medical
              information for personalized product recommendations, we will
              handle this data with the utmost care and in compliance with
              applicable laws.
              <br />
            </span>
            <span>
              1.3 Usage Data: We collect data on your interactions with our
              website and mobile applications to improve our services and
              enhance your overall experience. This includes device information,
              IP addresses, and browsing patterns.
            </span>
            <span>
              1.4 Payment Information: For purchases, we collect and process
              payment information securely through trusted third-party payment
              processors. <br />
              <br />
            </span>
          </p>
          <p>
            <span>
              <b>2. How We Use Your Information</b>
            </span>
            <span>
              2.1 Order Fulfillment: We use your information to process orders,
              deliver products, and provide customer support. Your medical
              information is used exclusively for personalized recommendations
              with your consent. <br />
            </span>
            <span>
              2.2 Communication: We may contact you with order updates,
              personalized product recommendations, and newsletters. You can
              opt-out of non-essential communications at any time.
            </span>
            <span>
              2.3 Legal Compliance: We may disclose your information if required
              by law, including government requests and legal proceedings.{" "}
              <br /> <br />
            </span>
          </p>
          <p>
            <span>
              <b>3. How We Share Your Information</b>
            </span>

            <span>
              3.1 Third-Party Partners: We may share your information with
              third-party partners involved in order fulfillment, shipping, and
              payment processing. These partners are bound by confidentiality
              agreements.
            </span>
            <span>
              3.2 Anonymized and Aggregated Data: We may share anonymized and
              aggregated data for research, analytics, and industry insights.{" "}
              <br />
              <br />
            </span>
          </p>
          <p>
            <span>
              <b> 4. Data Security</b>
            </span>
            <span>
              We implement robust security measures to protect your personal and
              medical information. This includes encryption, secure payment
              processing, and access controls. However, no method of
              transmission or storage is completely secure, and we cannot
              guarantee absolute security. <br /> <br />
            </span>
          </p>
          <p>
            <span>5. Your Choices.</span>
            <span>
              You have the right to opt-out of non-essential communications and
              can manage your communication preferences in your account
              settings. You can choose not to provide certain information, but
              this may limit your access to certain features. <br />
              <br />
            </span>
          </p>
          <p>
            <span>
              <b>6. Compliance with Cannabis Regulations</b>
            </span>
            <span>
              Calmosis is committed to complying with all applicable Ayurvedic
              medical Cannabis regulations. We will handle and store your
              information in accordance with these regulations. <br />
              <br />
            </span>
          </p>
          <p>
            <span>7. Changes to this Privacy Policy</span>
            <span>
              We may update this Privacy Policy to reflect changes in our
              practices. We will notify you of significant changes via email or
              through prominent notices on our website. <br /> <br />
            </span>
          </p>
          <p>
            <span>8. Contact Us</span>
            <span>
              If you have questions, concerns, or requests regarding this
              Privacy Policy or your data, please contact us at
              <a href="mailto:calm@calmosis.com">calm@calmosis.com</a>.
            </span>
          </p>
        </div>
      </div>

      <div className="line" />

      <Footer />
    </main>
  );
};

export default Privacy;

import React from "react";
import { Link } from "react-router-dom";
import Layout from "./../components/Layout/Layout";

const Pagenotfound = () => {
  return (
    <Layout title={"Calmosis-404"} minHeightNotRequired={true}>
      {/* <div className="pnf absolute top-[200px] text-center w-full"> */}
      {/* <h1 className="pnf-title text-wrapper text-wrapper2">404</h1>
        <h2 className="pnf-heading text-wrapper text-wrapper2">Oops ! Page Not Found</h2>
        <Link to="/" className="pnf-btn underline decoration-white">
          <p className="text-wrapper2 text-wrapper">
          Go Back
          </p>
        </Link> */}
      {/* </div> */}

      <div className="adasd relative">
        <div className=" center-div">
          <img className="imaga w-[100%]" src="/404-2.svg" alt="" />
        </div>

        {/* <button className="solid-btn">GO Back</button> */}
      </div>
      <div className=" center-div mt-[40vh] lg:mt-[12vh] ">
        <img className="imaga w-[100%]" src="/404-bg.svg" alt="" />
      </div>
    </Layout>
  );
};

export default Pagenotfound;

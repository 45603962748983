import { load as loadCashfree } from "@cashfreepayments/cashfree-js";
import { AxiosError } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import { useAuth } from "../../context/auth";
import axios, { baseURL } from "../../utils/axios";
import Footer from "../Basic/footer";
import Navbar from "../Basic/navbar";

const PeaceMantra = () => {
  const [isOverlay, setIsOverlay] = useState(false);
  const [auth, setAuth] = useAuth();
  const peace_img = "https://calmosis.com/peace_shop.png";
  const Sleep_img = "https://calmosis.com/Sleep_shop.png"; //   form
  const [deleteMessage, setDeleteMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [coupon, setCoupon] = useState();

  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    if (auth.user) {
      fetch(`${baseURL}/api/addresses?userId=${auth?.user?._id}`)
        .then((response) => response.json())
        .then((data) => {
          setAddresses(data);
        })
        .catch((error) => {
          console.error("Failed to fetch address data:", error);
        });
    }
  }, [auth?.user]);
  useEffect(() => {
    if (auth.user) {
      fetch(`${baseURL}/get-cart?userId=${auth?.user?._id}`)
        .then((response) => response.json())
        .then((data) => {
          setCartItems(data);
          if (data.length === 0) {
            setIsEmpty(true);
          } else {
            setIsEmpty(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching cart data:", error);
        });
    }
  }, [auth?.user]);

  const handleDeleteCartItem = (itemId) => {
    // Make a DELETE request to the server to delete the cart item
    fetch(`${baseURL}/api/delete-cart-item/${itemId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 204) {
          // Successful deletion on the server, now remove the item from your local state
          const updatedCart = cartItems.filter((item) => item._id !== itemId); // Use _id to match cart items
          localStorage.setItem("cartData", JSON.stringify(updatedCart));
          setCartItems(updatedCart);
        } else {
          // Handle the case where the server encountered an error
          console.error("Error deleting cart item");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDeleteAllAddresses = () => {
    setIsOverlay(true);

    // Get the currently logged-in user's ID from your authentication context or state
    const userId = auth?.user?._id; // Replace with the correct way to access the user's ID
    if (!userId) {
      // Handle the case when the user is not logged in
      return;
    }

    // Make a DELETE request to delete all addresses associated with the user
    fetch(`${baseURL}/api/addresses/delete/${userId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        setDeleteMessage(data.message);
        setAddresses([]);
      })
      .catch((error) => {
        console.error("Failed to delete addresses", error);
      });
  };

  const makePayment = async () => {
    // const stripe = await loadStripe(STRIPE_PK);

    // Check if there are existing addresses
    const selectedAddress = addresses.length > 0 ? addresses[0] : null;

    const body = {
      couponCode,
      products: cartItems,
      peace_img: peace_img,
      user: auth?.user?._id,
      username: auth?.user?.name,
      number: auth?.user?.phoneNumber,
      email: auth?.user?.email,
      addresss: selectedAddress
        ? {
            name: selectedAddress.name,
            email: selectedAddress.email,
            mobile: selectedAddress.mobile,
            gender: selectedAddress.gender,
            age: selectedAddress.age,
            address: selectedAddress.address,
            city: selectedAddress.city,
            state: selectedAddress.state,
            pincode: selectedAddress.pincode,
          }
        : {
            name,
            email,
            mobile,
            gender,
            age,
            address,
            city,
            state,
            pincode,
          },
    };

    try {
      const { data: session } = await axios.post(`${baseURL}/api/create-checkout-session`, body);

      const cashfree = await loadCashfree({
        mode: process.env.NODE_ENV === "development" ? "sandbox" : "production",
      });

      // Redirect to the Cashfree Checkout page
      await cashfree.checkout({
        paymentSessionId: session.id,
        redirectTarget: "_self", //optional (_self or _blank)
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const increaseQuantity = (itemId, currentQuantity) => {
    // Calculate the new quantity
    const newQuantity = currentQuantity + 1; // Calculate the new quantity as needed

    // Update the cart items with the new quantity
    const updatedCart = cartItems.map((cartItem) => {
      if (cartItem._id === itemId) {
        // Use "_id" to compare with the item ID
        return { ...cartItem, quantity: newQuantity }; // Update the quantity property
      }
      return cartItem;
    });

    // Update the cart items in the state
    setCartItems(updatedCart);

    // Send an API request to update the item's quantity in the database
    fetch(`${baseURL}/api/update-cart-item/${itemId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newQuantity }),
    })
      .then((response) => {
        if (response.status === 200) {
          // Item quantity updated successfully in the database
        } else {
          // Handle errors if the server encountered an issue
          console.error("Error updating item quantity");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const decreaseQuantity = (itemId, currentQuantity) => {
    if (currentQuantity > 1) {
      // Calculate the new quantity
      const newQuantity = currentQuantity - 1; // Calculate the new quantity as needed

      // Update the cart items with the new quantity
      const updatedCart = cartItems.map((cartItem) => {
        if (cartItem._id === itemId) {
          // Use "_id" to compare with the item ID
          return { ...cartItem, quantity: newQuantity }; // Update the quantity property
        }
        return cartItem;
      });

      // Update the cart items in the state
      console.log(updatedCart);
      setCartItems(updatedCart);

      // Send an API request to update the item's quantity in the database
      fetch(`${baseURL}/api/update-cart-item/${itemId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newQuantity }),
      })
        .then((response) => {
          if (response.status === 200) {
            // Item quantity updated successfully in the database
          } else {
            // Handle errors if the server encountered an issue
            console.error("Error updating item quantity");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const calculateTotalPrice = useCallback(() => {
    let totalPrice = 0;
    for (const item of cartItems) {
      totalPrice += item.price * item.quantity;
    }
    return totalPrice;
  }, [cartItems]);

  const couponDetails = useMemo(() => {
    const orderAmount = calculateTotalPrice();

    if (!coupon) return [orderAmount, ""];

    if (coupon.type === "percentage") {
      const discAmount = (coupon.value / 100) * orderAmount;
      return [orderAmount - discAmount, `₹${discAmount} (${coupon.value}% OFF)`];
    }

    return [orderAmount - coupon.value, `₹${coupon.value}`];
  }, [coupon, calculateTotalPrice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = auth?.user?._id;
    const newAddress = {
      user: userId,
      name,
      email,
      mobile,
      gender,
      age,
      address,
      city,
      state,
      pincode,
    };

    try {
      const response = await fetch(`${baseURL}/api/addresses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newAddress),
      });
      if (response.status === 201) {
        // Address created successfully
        console.log("Address created successfully");
        fetchAddresses();
        setIsOverlay(false);
      } else {
        console.error("Failed to create an address");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAddresses = async () => {
    if (auth.user) {
      fetch(`${baseURL}/api/addresses?userId=${auth?.user?._id}`)
        .then((response) => response.json())
        .then((data) => {
          setAddresses(data);
        })
        .catch((error) => {
          console.error("Failed to fetch address data:", error);
        });
    }
  };

  const applyCouponCode = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`/api/coupons/${couponCode}`);
      setCoupon(data);
      setCouponModalOpen(false);
    } catch (err) {
      console.error("applyCoupon:", err);
      if (err instanceof AxiosError) {
        toast.error(err.response.data.error);
      }
    }
  };

  return (
    <>
      <main className="shop_list ">
        <Navbar />
        <div className="content  ">
          <div className="detailss sm:py-16 xs:py-8 py-12 sm:p-16 xs:p-8 px-6 py-12 md:max-w-[200vh] md:max-h-[100%] max-h-[70%] max-w-[200vh] flex justify-centre items-center justify-center sm:gap-10 gap-0 flex-col sm:p-8 p-4">
            <div className=" relative max-md:top-[-4vh] ">
              {!auth?.user ? (
                <>
                  <div className="empty">
                    <img src="./flower_cart.png" alt="" />
                    <p>Please login to view your cart</p>
                  </div>
                </>
              ) : (
                <>
                  {cartItems.length === 0 ? (
                    <div className="empty">
                      <img src="./flower_cart.png" alt="" />
                      <p>Your cart is empty</p>
                    </div>
                  ) : (
                    <div className="cards flex sm:gap-10 gap-4">
                      {cartItems.map((item) => {
                        return (
                          <div
                            className={`relative rightdetails sm:w-[480px] w-[auto] max-w-[480px] sm:h-[auto] h-[200px] flex items-center text-center flex-col sm:p-10 p-4`}
                            key={item.id}
                          >
                            <div className=" relative sm:absolute rightdetailsss">
                              {item.name === "Peace Mantra" && peace_img && <img src={peace_img} alt="" className="md:w-[190px] w-[80px] md:h-[180px] h-[70px]" />}
                              {item.name !== "Peace Mantra" && Sleep_img && <img src={Sleep_img} alt="" className="md:w-[190px] w-[80px] md:h-[180px] h-[70px]" />}
                            </div>
                            <div className="middle">
                              <h2 className="md:text-[25px] text-[15px]">{item.name}</h2>
                              {/* <p className="md:text-[20px] text-[10px]">
                                {item.flavour} - {item.variant}
                              </p> */}
                              <div className="p-2">
                                <button className="" onClick={() => decreaseQuantity(item._id, item.quantity)}>
                                  -
                                </button>{" "}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <span>{item.quantity}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
                                <button className="" onClick={() => increaseQuantity(item._id, item.quantity)}>
                                  +
                                </button>
                              </div>
                              <p>{item.price * item.quantity}</p>
                            </div>
                            <div className="right sm:right-[80px] sm:top-[90px] top-[190px] md:text-[30px] text-[20px] items-end absolute  ">
                              <button onClick={() => handleDeleteCartItem(item._id)}>
                                <AiOutlineDelete />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="w-[95%] sm:w-[83%] flex justify-end flex-col sm:p-8 p-4 rounded-[32px] border-[2px] border-[white] relative">
              <div className="center-itemss">
                {addresses.length > 0 ? (
                  <>
                    <button className="btn top-[5vh] text-[20px] right-[5vh] cursor-pointer absolute" onClick={handleDeleteAllAddresses}>
                      <AiOutlineDelete onClick={handleDeleteAllAddresses} />
                    </button>
                  </>
                ) : (
                  <div></div>
                )}

                {addresses.map((address) => (
                  <p className="addres center-itemsss" key={address._id}>
                    Delivery Address: <br /> {address.name}, <br /> {address.mobile}, <br /> {address.address}, {address.city}, {address.state}, {address.pincode}
                  </p>
                ))}

                <table className="modern-table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>
                          ₹{item.price} X {item.quantity}
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                      <td>Doctor's consultation</td>
                      <td>-₹500</td>
                    </tr> */}
                    {coupon && (
                      <tr>
                        <td>Coupon Applied</td>
                        <td>
                          - {couponDetails[1]}{" "}
                          <button
                            className="border rounded-none px-2 border-yellow-400"
                            onClick={() => {
                              setCoupon(undefined);
                              setCouponCode("");
                            }}
                          >
                            x
                          </button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Order Total</td>
                      <td>₹{couponDetails[0]}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="full-width"></div>
                {addresses.length > 0 ? (
                  <div className="flex flex-col md:flex-row gap-2">
                    <Modal active={couponModalOpen} setActive={setCouponModalOpen}>
                      <form className="flex flex-col gap-2" onSubmit={applyCouponCode}>
                        <h1 className="text-2xl">Coupon Code</h1>
                        <input type="text" className="p-2 text-black" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                        <button type="submit" className="btn cursor-pointer p-2 self-end">
                          Apply
                        </button>
                      </form>
                    </Modal>
                    {!coupon && (
                      <button className="btn mt-4 cursor-pointer p-2 !w-full" onClick={() => setCouponModalOpen((c) => !c)}>
                        Apply Coupon
                      </button>
                    )}
                    <button className="btn md:mt-4 cursor-pointer flex items-center" onClick={makePayment}>
                      <span style={{ whiteSpace: "nowrap", padding: 4 }}>Proceed to Payment</span>
                      <svg width="58" height="46" viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="55" height="43" fill="#F2D101" stroke="#F2D101" strokeWidth="3" />
                        <path
                          d="M16 21.5C15.1716 21.5 14.5 22.1716 14.5 23C14.5 23.8284 15.1716 24.5 16 24.5V21.5ZM42.0607 24.0607C42.6464 23.4749 42.6464 22.5251 42.0607 21.9393L32.5147 12.3934C31.9289 11.8076 30.9792 11.8076 30.3934 12.3934C29.8076 12.9792 29.8076 13.9289 30.3934 14.5147L38.8787 23L30.3934 31.4853C29.8076 32.0711 29.8076 33.0208 30.3934 33.6066C30.9792 34.1924 31.9289 34.1924 32.5147 33.6066L42.0607 24.0607ZM16 24.5L41 24.5V21.5L16 21.5V24.5Z"
                          fill="#466F44"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <button className="btn mt-4 cursor-pointer flex items-center" onClick={() => setIsOverlay(true)}>
                    <span className="sm:text-[auto] text-[14px] p-1">Add Address</span>
                    <svg width="58" height="46" viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:w-[auto] sm:h-[auto] w-[43px] h-[33px]">
                      <rect x="1.5" y="1.5" width="55" height="43" fill="#F2D101" stroke="#F2D101" strokeWidth="3" />
                      <path
                        d="M16 21.5C15.1716 21.5 14.5 22.1716 14.5 23C14.5 23.8284 15.1716 24.5 16 24.5V21.5ZM42.0607 24.0607C42.6464 23.4749 42.6464 22.5251 42.0607 21.9393L32.5147 12.3934C31.9289 11.8076 30.9792 11.8076 30.3934 12.3934C29.8076 12.9792 29.8076 13.9289 30.3934 14.5147L38.8787 23L30.3934 31.4853C29.8076 32.0711 29.8076 33.0208 30.3934 33.6066C30.9792 34.1924 31.9289 34.1924 32.5147 33.6066L42.0607 24.0607ZM16 24.5L41 24.5V21.5L16 21.5V24.5Z"
                        fill="#466F44"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="line relative" />
        <Footer />
      </main>

      <div className={`overlay-shop ${isOverlay ? "open" : ""}`} data-scroll data-scroll-sticky data-scroll-target="#shop">
        <div className="close">
          <FiX onClick={() => setIsOverlay(false)} color="white" size={32} />
        </div>
        <div className="content">
          <div className="left">
            <form onSubmit={handleSubmit}>
              {/* <div className="input-flex">
								<div className="form-control">
									<label htmlFor="name">Full Name *</label>
									<input
										type="text"
										value={name}
										onChange={(e) => setName(e.target.value)}
										id="name"
										placeholder="Full Name"
										required
									/>
								</div>
								<div className="form-control">
									<label htmlFor="email">Email *</label>
									<input
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										id="email"
										placeholder="Email ID"
										required
									/>
								</div>
							</div> */}
              <div className="form-control">
                <label htmlFor="name">Full Name *</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} id="name" placeholder="Full Name" required />
              </div>
              <div className="form-control">
                <label htmlFor="mobile">Mobile *</label>
                <input type="number" value={mobile} onChange={(e) => setMobile(e.target.value)} id="mobile" placeholder="Mobile Phone" required />
              </div>

              <div className="input-flex">
                <div className="form-control">
                  <label htmlFor="gender">Gender *</label>
                  <div className="input-flex">
                    <div className="form-control gender">
                      <input type="radio" name="gender" value="male" checked={gender === "male"} onChange={() => setGender("male")} /> <span>Male</span>
                    </div>
                    <div className="form-control gender">
                      <input type="radio" name="gender" value="female" checked={gender === "female"} onChange={() => setGender("female")} /> <span>Female</span>
                    </div>
                    <div className="form-control gender">
                      <input type="radio" name="gender" value="other" checked={gender === "other"} onChange={() => setGender("other")} />
                      <span>Other</span>
                    </div>
                    <div className="form-control gender"></div>
                  </div>
                </div>

                <div className="form-control age">
                  <label htmlFor="age">Age *</label>
                  <input type="text" value={age} onChange={(e) => setAge(e.target.value)} id="age" placeholder="Age" required />
                </div>
              </div>

              <div className="full-width">
                <div className="form-control">
                  <label htmlFor="address">Address *</label>
                  <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} id="address" placeholder="Address" required />

                  <div className="input-flex">
                    <div className="form-control">
                      <input type="text" value={city} onChange={(e) => setCity(e.target.value)} id="city" placeholder="City" required />
                    </div>
                    <div className="form-control">
                      <input type="text" value={state} onChange={(e) => setState(e.target.value)} id="state" placeholder="State" required />
                    </div>
                    <div className="form-control">
                      <input type="number" value={pincode} onChange={(e) => setPincode(e.target.value)} id="pincode" placeholder="Pincode" required />
                    </div>
                  </div>
                </div>
              </div>

              <button className="btnn mt-4" type="submit">
                <a type="submit">
                  <span>Add Address</span>
                  <svg width="58" height="46" viewBox="0 0 58 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.5" y="1.5" width="55" height="43" fill="#F2D101" stroke="#F2D101" strokeWidth="3" />
                    <path
                      d="M16 21.5C15.1716 21.5 14.5 22.1716 14.5 23C14.5 23.8284 15.1716 24.5 16 24.5V21.5ZM42.0607 24.0607C42.6464 23.4749 42.6464 22.5251 42.0607 21.9393L32.5147 12.3934C31.9289 11.8076 30.9792 11.8076 30.3934 12.3934C29.8076 12.9792 29.8076 13.9289 30.3934 14.5147L38.8787 23L30.3934 31.4853C29.8076 32.0711 29.8076 33.0208 30.3934 33.6066C30.9792 34.1924 31.9289 34.1924 32.5147 33.6066L42.0607 24.0607ZM16 24.5L41 24.5V21.5L16 21.5V24.5Z"
                      fill="#466F44"
                    />
                  </svg>
                </a>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeaceMantra;

import Footer from "../../Basic/footer";
import Navbar from "../../Basic/navbar";
import React from "react";

const Refund = () => {
  return (
    <main className="block">
      <Navbar />
      <div className="content">
        <div className="one">
          <p></p>
          <h2>Return Policy</h2>
          <p>
            <span>
              At Calmosis, we are committed to providing you with high-quality
              products and a satisfactory shopping experience. If, for any
              reason, you are not completely satisfied with your purchase, we
              offer a straightforward return and refund process. Please notify
              us within 5 days of receiving your order if you encounter any
              issues with the items.
            </span>
            <span>
              {" "}
              Contact our customer support team at calm@calmosis.com and provide
              the following details:
            </span>
            <span>
              1) Proof of purchase (order number, receipt, or invoice).
              <br />
              <br />
              2) A clear description of the issue or reason for the return.
            </span>
          </p>
        </div>

        <div className="one">
          <h2>Refund Policy</h2>
          <p>
            <span>
              If the return is approved, we will promptly process a refund to
              the original method of payment or, if preferred, send you a
              replacement item.
            </span>
            <span>
              Refunds are typically processed within 5-7 business days after
              approval of return.
            </span>
            <span>
              For any questions or concerns regarding our Return and Refund
              Policy, please contact our customer support team at
              calm@calmosis.com.
            </span>
            <span>
              {" "}
              We are dedicated to making your wellness experience with Calmosis
              as seamless as possible.
            </span>
          </p>
        </div>
      </div>

      <div className="line" />

      <Footer />
    </main>
  );
};

export default Refund;

import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import { Button } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import { toast } from "react-toastify";
import BasicTable from "../../components/Layout/BasicTable";
import DoctorMenu from "../../components/Layout/DoctorMenu";
import DashNav from "../../components/Layout/dash-nav";
import Modal from "../../components/Modal";
import Prescription from "../../components/Prescription";
import { useAuth } from "../../context/auth";
import axios, { baseURL } from "../../utils/axios";
import { AxiosError } from "axios";

function arrayBufferToString(arrayBuffer) {
  var byteArray = new Uint8Array(arrayBuffer);
  var byteString = "";
  for (var i = 0; i < byteArray.byteLength; i++) {
    byteString += String.fromCodePoint(byteArray[i]);
  }
  return byteString;
}

const AddPrescriptionDoctor = () => {
  const tableHeaders = [
    {
      field: "dateTime",
      headerName: "Date & Time",
      minWidth: 240,
    },
    {
      field: "orderNumber",
      headerName: "Order #",
      minWidth: 160,
    },
    {
      field: "customerInfo",
      headerName: "Customer",
      minWidth: 240,
      renderCell({ value }) {
        return <p className="capitalize">{value}</p>;
      },
    },
    {
      field: "productInfo",
      headerName: "Product",
      minWidth: 240,
      sortable: false,
      renderCell: ({ value }) => (
        <ul>
          {value.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </ul>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 240,
      sortable: false,
      renderCell({ value }) {
        return (
          <div className="flex gap-4">
            <p className="capitalize">{value[0]}</p>
            <button title="Approve" className="w-6 h-6 border-white border rounded-none grid place-content-center leading-none" onClick={() => handleApprove(value[1])}>
              👍
            </button>
            <button title="Cancel" className="w-6 h-6 border-white border rounded-none grid place-content-center leading-none" onClick={() => handleCancel(value[1])}>
              👎
            </button>
          </div>
        );
      },
    },
    {
      field: "prescription",
      headerName: "Prescription",
      minWidth: 320,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell({ value }) {
        return (
          <Button
            disabled={value.prescription}
            className="rounded-sm w-48"
            onClick={() => {
              setCustomerGender(value.address.gender);
              setCustomerName(value.address.name);
              setCustomerAge(value.address.age);
              setModalData(value);
            }}
          >
            {value.prescription ? "Already uploaded" : `Upload prescription`}
          </Button>
        );
      },
    },
  ];

  const [auth] = useAuth();
  const [modalData, setModalData] = useState(undefined);
  const [preview, setPreview] = useState(undefined);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [orders, setOrders] = useState([]);

  const [customerGender, setCustomerGender] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerAge, setCustomerAge] = useState("");
  // Data by doctor
  const [howToUse, setHowToUse] = useState("");
  // const [allergies, setAllergies] = useState("Allergies patient has");
  const [medicalHistory, setMedicalHistory] = useState("");
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState("");

  const provisionalDiagnosisId = useId();
  const medicalHistoryId = useId();
  // const allergiesId = useId();
  const howToUseId = useId();
  const customerNameId = useId();
  const customerAgeId = useId();
  const customerGenderId = useId();

  const ac = useMemo(() => new AbortController(), []);

  /**
   * Fetch orders from your API when the component mounts
   */
  const fetchOrders = useCallback(async () => {
    try {
      setLoadingOrders(true);
      const { data } = await axios.get(`${baseURL}/api/pending-orders`, {
        signal: ac.signal,
      });
      setOrders(
        data.map((o) => ({
          _id: o._id,
          dateTime: dayjs(o.createdAt).format("DD/MM/YY | hh:mmA"),
          orderNumber: o.orderId,
          customerInfo: o.address.name,
          productInfo: o.lineItems.map((l) => `${l.name} x${l.quantity}`),
          status: [o.approval, o._id],
          prescription: o,
        }))
      );
    } catch (error) {
      console.error("Error fetching orders:", error.message);
      toast.error("Failed loading orders!");
    } finally {
      setLoadingOrders(false);
    }
  }, [ac]);

  useEffect(() => {
    fetchOrders();
    return () => {
      ac.abort();
    };
  }, [ac, fetchOrders]);

  const handleApprove = async (orderId) => {
    if (!orders.find(({ _id }) => _id === orderId)?.prescription?.prescription) {
      return toast.warning("Please add a prescription...");
    }

    try {
      await axios.put(`${baseURL}/api/update-approval/${orderId}`);
      fetchOrders();
    } catch (error) {
      console.error("Error approving order:", error.message);
      if (error instanceof AxiosError) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleCancel = async (orderId) => {
    try {
      await axios.put(`${baseURL}/api/update-cancel/${orderId}`);
      fetchOrders();
    } catch (error) {
      console.error("Error cancelling order:", error);
      if (error instanceof AxiosError) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  const savePrescription = async (orderId, prescription) => {
    try {
      await axios.post(`${baseURL}/api/update-prescription/${orderId}`, {
        prescription: arrayBufferToString(await prescription.arrayBuffer()),
      });
      toast.success("Prescription saved");
      setModalData(undefined);
      setPreview(false);
      fetchOrders();
    } catch (error) {
      console.error("Error updating prescription:", error);
      toast.error(error.message);
    }
  };

  const clearFields = () => {
    setHowToUse("");
    setMedicalHistory("");
    setProvisionalDiagnosis("");
  };

  return (
    <main className="dashboard order" data-scroll-container id="home">
      <DoctorMenu />

      {!!modalData && !preview ? (
        <Modal
          active
          setActive={() => {
            setModalData();
            clearFields();
          }}
        >
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-xl capitalize font-bold">{customerName}</h2>
                <h3 className="capitalize">
                  {customerAge} Years, {customerGender}
                </h3>
              </div>
              <div className="flex flex-col items-end">
                {modalData.lineItems.map((l, k) => (
                  <h2 key={k} className="font-bold">{`${l.name} x${l.quantity}`}</h2>
                ))}
              </div>
            </div>

            <hr className="my-4" />

            <form
              onSubmit={(e) => {
                e.preventDefault();
                setPreview(true);
              }}
              className="flex flex-col gap-1 form-control"
            >
              <label htmlFor={customerNameId}>Customer Name, Age and Gender</label>
              <input
                type="text"
                id={customerNameId}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                className="py-2.5 px-5 bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4"
              />
              <div className="flex gap-4 w-full">
                <input
                  min={18}
                  type="number"
                  id={customerAgeId}
                  value={customerAge}
                  onChange={(e) => setCustomerAge(e.target.value)}
                  className="py-2.5 px-5 grow bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4"
                />
                <input
                  min={18}
                  type="text"
                  id={customerGenderId}
                  value={customerGender}
                  onChange={(e) => setCustomerGender(e.target.value)}
                  className="py-2.5 px-5 grow bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4"
                />
              </div>
              <label htmlFor={medicalHistoryId}>Medical History</label>
              <input
                type="text"
                id={medicalHistoryId}
                value={medicalHistory}
                onChange={(e) => setMedicalHistory(e.target.value)}
                className="py-2.5 px-5 bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4"
              />

              {/* <label htmlFor={allergiesId}>Allergies</label>
              <input
                type="text"
                id={allergiesId}
                value={allergies}
                onChange={(e) => setAllergies(e.target.value)}
                className="py-2.5 px-5 bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4"
              /> */}

              <label htmlFor={howToUseId}>How To Use</label>
              <input
                type="text"
                id={howToUseId}
                value={howToUse}
                onChange={(e) => setHowToUse(e.target.value)}
                className="py-2.5 px-5 bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4"
              />

              <label htmlFor={provisionalDiagnosisId}>Provisional Diagnosis</label>
              <textarea
                id={provisionalDiagnosisId}
                value={provisionalDiagnosis}
                onChange={(e) => setProvisionalDiagnosis(e.target.value)}
                className="py-2.5 px-5 bg-transparent font-bold border border-white border-opacity-75 text-lg outline-none mb-4 h-44"
              />

              <div className="flex gap-4 self-end">
                <button
                  type="submit"
                  className="border border-white rounded-none py-2.5 px-5 h-auto font-bold text-white hover:text-black hover:bg-white transition-colors duration-300 max-w-sm"
                >
                  Preview
                </button>
                <button
                  type="reset"
                  onClick={() => {
                    setModalData(undefined);
                    clearFields();
                  }}
                  className="border border-white rounded-none py-2.5 px-5 h-auto font-bold text-white hover:text-black hover:bg-white transition-colors duration-300 max-w-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      ) : null}

      {preview
        ? (() => {
            const prescription = (
              <Prescription
                provisionalDiagnosis={provisionalDiagnosis}
                medicalHistory={medicalHistory}
                customerData={{
                  ...modalData,
                  address: {
                    ...modalData.address,
                    age: customerAge || modalData.address.age,
                    name: customerName || modalData.address.name,
                    gender: customerGender || modalData.address.gender,
                  },
                }}
                howToUse={howToUse}
                doctor={auth.user}
              />
            );
            return (
              <Modal active setActive={setPreview}>
                <div className="w-full h-screen flex flex-col gap-4 justify-center">
                  <PDFViewer className="w-full h-full" showToolbar={false}>
                    {prescription}
                  </PDFViewer>

                  <div className="flex gap-4 self-end">
                    <BlobProvider document={prescription} fileName="Prescription.pdf">
                      {({ loading, blob }) => (
                        <button
                          type="reset"
                          onClick={() => {
                            savePrescription(modalData._id, blob);
                          }}
                          className="border border-white rounded-none py-2.5 px-5 h-auto font-bold text-white hover:text-black hover:bg-white transition-colors duration-300 max-w-sm"
                        >
                          {loading ? "Loading..." : "Save"}
                        </button>
                      )}
                    </BlobProvider>

                    <button
                      type="reset"
                      onClick={() => setPreview(false)}
                      className="border border-white rounded-none py-2.5 px-5 h-auto font-bold text-white hover:text-black hover:bg-white transition-colors duration-300 max-w-sm"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </Modal>
            );
          })()
        : null}

      <div className="mainbar">
        <DashNav />
        <hr className="line" />
        <div className="label">
          <div className="element-terry-francine center-itemsss">
            <p className="text-4xl">Add Prescription</p>
          </div>
        </div>
        <h1 className="mx-8 mt-2">
          {loadingOrders ? (
            <p className="mx-4 text-white">Loading orders...</p>
          ) : orders.length ? (
            <BasicTable headers={tableHeaders} data={orders} />
          ) : (
            <p className="mx-4 text-white">No pending orders for approval.</p>
          )}
        </h1>
      </div>
    </main>
  );
};

export default AddPrescriptionDoctor;

import React, { useEffect } from "react";
import axios from "../utils/axios";
import { useSearchParams, useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";

const Cancel = () => {
  const [params, _] = useSearchParams();
  const navigate = useNavigate();

  useEffect(async () => {
    try {
      const session_id = params.get("session_id");
      await axios.get(`api/update-payment-status?sessionId=${session_id}`);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleCancelClick = () => {
    navigate("/");
  };

  return (
    <Layout>
      <>
        <div className="adasd relative ">
          <div className="label center-div">
            <img className="imaga" src="/cancel.gif" alt="" />
            <div className="mt-[-50px] w-[100%] text-center flex flex-col items-center">
              <div className="text-wrapper">Order Failed</div>
              <p className="text-wrapperr pt-1 text-sm">
                Place your order again
              </p>
              {/* <button className="solid-btn" onClick={handleCancelClick}>
                Continue shopping
              </button> */}
            </div>
          </div>
        </div>
        <div className="line relative" style={{ marginTop: "40px" }} />
      </>
    </Layout>
  );
};

export default Cancel;

import React, { useEffect, useState } from "react";
import axios from "../utils/axios";

import { useSearchParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const Success = () => {
  const [params] = useSearchParams();
  const [success, setSuccess] = useState(undefined);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderId = params.get("order_id");
        const response = await axios.get(`/get-orders-by-session/${orderId}`);
        const orders = response.data.data || [];
        const lineItems = orders.flatMap((order) => order.lineItems || []);
        const PRICES = orders.flatMap((order) => order.price || []);

        console.log("Orders fetched successfully!");
        await axios.post("/email/sendEmail/", {
          lineItems,
          PRICES,
          orderId,
        });
        console.log("Extracted line items sent to a different API successfully!");
      } catch (error) {
        console.error("Failed to fetch orders", error);
      }
    };

    async function updateOrder() {
      try {
        const orderId = params.get("order_id");
        const { data } = await axios.get(`api/update-payment-status?orderId=${orderId}`);
        setSuccess(data.success);
        fetchOrders();
      } catch (error) {
        console.error("updateOrder:", error);
        if (error instanceof AxiosError) {
          setSuccess(false);
          toast.error(error.response.data.error);
        }
      }
    }
    updateOrder();
  }, [params]);

  return (
    <Layout setCartEmpty>
      {success === undefined ? null : (
        <>
          <div className="adasd relative">
            <div className="label center-div">
              <img className="imaga" src={success ? "/success.gif" : "/cancel.gif"} alt="" />
            </div>
            <div className="text-wrapper">{success ? "Thanks for ordering" : "Payment failed"}</div>

            {success && (
              <p className="text-wrapperr pt-1">
                The doctor will be in touch shortly <br />
                {/* <br /> payment confirmation will be sent to your email{" "}
            {auth?.user?.email}.<br /> */}
                Orders will be delivered in 5-10 days.
              </p>
            )}

            <a href={success ? "/" : "/checkout"}>
              <button className="solid-btn">{success ? "Continue shopping" : "Retry"}</button>
            </a>
          </div>
          <div className="line relative" style={{ marginTop: "40px" }} />
        </>
      )}
    </Layout>
  );
};

export default Success;

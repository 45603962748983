import Footer from "../../Basic/footer";
import Navbar from "../../Basic/navbar";
import React from "react";

const Store = () => {
  return (
    <main className="block">
      <Navbar />
      <div className="content">
        <h2 className="out">Store Policy</h2>

        <div className="one">
          <h2>Customer Care</h2>
          <p>
            <span>
              At Calmosis, we prioritize your satisfaction. For assistance, call
              our Customer Care hotline at +91 7090-890-890 during our business
              hours (9am to 6pm). Alternatively, drop us an email at
              calm@calmosis.com for prompt responses within 2 business days.
              Your queries and feedback matter to us, and our team is here to
              ensure a seamless experience with our products and services.
            </span>
          </p>
        </div>

        <div className="one">
          <h2>Privacy & Safety</h2>
          <p>
            <span>
              At Calmosis, we prioritize the confidentiality and privacy of our
              patients. We understand the sensitivity of personal health
              information and adhere to stringent measures to ensure its
              protection. Patient confidentiality is maintained through secure
              storage systems, encrypted data transmission, and restricted
              access only to authorized personnel involved in the patient's
              care. We strictly comply with all relevant data protection laws
              and regulations. Rest assured that your health information is
              handled with the utmost care and confidentiality, reinforcing our
              commitment to providing a secure and trustworthy natural wellness
              experience.
            </span>
          </p>
        </div>

        <div className="one">
          <h2>Distribution Inquiries</h2>
          <p>
            <span>
              For Distribution inquiries, please feel free to drop us an email
              at calm@calmosis.com. We look forward to discussing potential
              collaborations and providing you with the information and support
              you need for a successful partnership.
            </span>
          </p>
        </div>

        {/* <div className="one">
                    <h2>Payment Methods</h2>
                    <p>
                        <span>
                        I'm a paragraph. Click here to add your own text and edit me. I’m a great place for you to tell a story and let your users know a little more about you.
                        </span>
                    </p>
                </div> */}
      </div>

      <div className="line" />

      <Footer />
    </main>
  );
};

export default Store;

import { AxiosError } from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AdminMenu from "../../components/Layout/AdminMenu";
import DashNav from "../../components/Layout/dash-nav";
import axios from "../../utils/axios";

const price = {
  peaceMantra: 4500,
  sleepMantra: 4500,
};

const AddOrder = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const [peaceMantraQuantity, setPeaceMantraQuantity] = useState(0);
  const [sleepMantraQuantity, setSleepMantraQuantity] = useState(0);

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();

    if (!gender) return toast.error("Please select gender");
    if (!(peaceMantraQuantity || sleepMantraQuantity)) return toast.error("Please select product quantity");

    try {
      const lineItems = [];

      if (peaceMantraQuantity) {
        lineItems.push({
          name: "Peace Mantra",
          quantity: peaceMantraQuantity,
          price: price.peaceMantra,
        });
      }

      if (sleepMantraQuantity) {
        lineItems.push({
          name: "Sleep Mantra",
          quantity: sleepMantraQuantity,
          price: price.sleepMantra,
        });
      }

      const data = {
        address: {
          name,
          mobile: contact,
          gender,
          age,
          address,
          city,
          state,
          pincode,
        },
        email,
        lineItems,
        couponCode,
        username: name.split(" ")[0].toLowerCase(),
      };

      const { data: response } = await axios.post("/api/admin/create-order", data);
      console.log(response);
      toast.success("Order created successfully");
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        toast.error(error.response.data.error);
      }
    }
  };

  const dec = (func) => () => func((v) => (!v ? v : v - 1));
  const inc = (func) => () => func((v) => v + 1);

  return (
    <main className="dashboard account" data-scroll-container id="addorder">
      <AdminMenu />
      <div className="container-fluid mainbar p-3">
        <DashNav />
        <div className="login">
          <div className="content">
            <form onSubmit={handleCreate} className="!mt-10">
              <h1 className="text-2xl">Add Order</h1>

              <hr />

              <div className="m-4 w-75">
                <div className="mb-3">
                  <label htmlFor="username">Buyer Name</label>
                  <input required type="text" name="username" value={name} placeholder="Name of buyer" className="form-control" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Email Address</label>
                  <input required type="email" name="email" value={email} placeholder="mail@calmosis.com" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="contact">Contact Number (w/o country code)</label>
                  <input
                    required
                    type="text"
                    name="contact"
                    value={contact}
                    pattern="^[0-9]{10}$"
                    placeholder="Contact of buyer"
                    className="form-control"
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
                <div className="mb-3 flex flex-col gap-2">
                  <label htmlFor="gender">Gender</label>
                  <select name="gender" value={gender} onChange={(e) => setGender(e.target.value)} className="p-2 text-black">
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="age">Age</label>
                  <input required type="number" min={18} name="age" value={age} placeholder="Age of buyer" className="form-control" onChange={(e) => setAge(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="address">Address</label>
                  <input required type="text" name="address" value={address} placeholder="Address of buyer" className="form-control" onChange={(e) => setAddress(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="city">City</label>
                  <input required type="text" name="city" value={city} placeholder="City (e.g. Bangalore)" className="form-control" onChange={(e) => setCity(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="state">State</label>
                  <input required type="text" name="state" value={state} placeholder="State (e.g. Karnataka)" className="form-control" onChange={(e) => setState(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="Pincode">State</label>
                  <input
                    required
                    type="number"
                    name="pincode"
                    value={pincode}
                    placeholder="Pincode (e.g. 123456)"
                    className="form-control"
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>

                <hr className="mb-3" />

                <h1 className="text-2xl mb-3">Products</h1>

                <div className="flex flex-col gap-4">
                  <div className="flex gap-4 items-center text-lg">
                    <img src="/peace_shop.png" width={150} alt="" />

                    <div className="flex flex-col gap-2">
                      <span>Peace Mantra</span>

                      <div className="flex items-center gap-5 flex-grow justify-center">
                        <button className="bg-white p-2 w-10 text-black disabled:opacity-60" disabled={!peaceMantraQuantity} type="button" onClick={dec(setPeaceMantraQuantity)}>
                          -
                        </button>
                        <span>{peaceMantraQuantity}</span>
                        <button className="bg-white p-2 w-10 text-black" type="button" onClick={inc(setPeaceMantraQuantity)}>
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-4 items-center text-lg">
                    <img src="/sleep_shop.png" width={150} alt="" />

                    <div className="flex flex-col gap-2">
                      <span>Sleep Mantra</span>

                      <div className="flex items-center gap-5 flex-grow justify-center">
                        <button className="bg-white p-2 w-10 text-black disabled:opacity-60" disabled={!sleepMantraQuantity} type="button" onClick={dec(setSleepMantraQuantity)}>
                          -
                        </button>
                        <span>{sleepMantraQuantity}</span>
                        <button className="bg-white p-2 w-10 text-black" type="button" onClick={inc(setSleepMantraQuantity)}>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-3" />

                <h1 className="text-2xl mb-3">Apply Coupon</h1>
                <div className="mb-3">
                  <input type="text" name="couponCode" value={couponCode} placeholder="e.g. DISC15" className="form-control" onChange={(e) => setCouponCode(e.target.value)} />
                </div>

                <hr className="mb-3" />

                <button type="submit" className="btn btn-primary p-2">
                  Create Order
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddOrder;

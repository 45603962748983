import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

/**
 * Columns example
 *
 * [
    {
      field: 'fullName',
      headerName: 'Full name',
      type: 'number',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div>
          <a>{params.row.firstName}</a>
          <br />
          <a>{params.row.lastName}</a>
        </div>
      ),
    },
  ]
 *
 */

/**
 * Rows Example
 *
  [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  ]
 */

export default function DataTable({ headers, data, isMobile, rowHeight = 52 }) {
  return (
    <div
      style={{
        width: "100%",
        fontColor: "white",
      }}
    >
      <DataGrid
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "#ffffff",
          "& *": {
            color: "#ffffff",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "5px",
            fontSize: "12px",
          },
        }}
        rows={data}
        rowHeight={rowHeight}
        columns={headers}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        getRowId={(row) => row._id}
        pageSizeOptions={[20, 50]}
        // autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        // rowHeight={isMobile?168:52}
        // getRowHeight={() => "auto"}
        // getEstimatedRowHeight={() => 200}
        // loading
      />
    </div>
  );
}

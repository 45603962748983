import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import comfortaa from "../../assets/Comfortaa.ttf";
import trebuchet from "../../assets/trebuc.ttf";
import logo from "../../assets/logo.png";
import dayjs from "dayjs";

const grey = "#ccc";
const backgroundColor = "#466f44";
const notRecommendedFor = ["Individuals under 18", "Pregnant or breastfeeding women", "Those with heart conditions" /* "Individuals with diabetes" */];
const general = [
  /* {
    title: "Can individuals with certain health conditions safely use cannabis extract?",
    text: "Not recommended for: Individuals under 18, Pregnant or breastfeeding women, Those with heart conditions, Individuals with diabetes.",
  }, */
  {
    title: "How long does it take for medical cannabis extract to take effect?",
    text: "The onset of effects from medical cannabis extract can vary among individuals due to differences in metabolism. Generally, it is expected to show effects within approximately 60 to 90 minutes after consumption. It's important for individuals to be patient and allow sufficient time for the effects to manifest before considering additional doses.",
  },
];
const notes = [
  "The oil has to be taken under the tongue (sublingual). Hold the oil for 15-20 seconds before swallowing as the oil gets absorbed directly from under the tongue",
  "Take the night dose roughly 2 hours before bed time (15-20 minutes after food)",
  "Drink enough fluids as you might experience symptoms like dry mouth, thirst or mild constipation",
  "Avoid engaging in stimulatory activities like smartphone, laptop, TV. You can engage in reading/ talking/ listening to music/ meditation/ yoga/ walking",
  "If you feel dizzy have a glass of lemon juice with black pepper",
  "Avoid alcohol as it neutralizes the effect of cannnabis. If you do consume alcohol then skip the CBD dose that day",
  "Do not mix with any food or beverages",
  "Ideally should be had 20 minutes after food",

  "Do not drive/ control heavy machinery for 4-6 hours after taking the oil",
  "Follow up after 15 days of starting with the drops",
  "Keep out of reach of children",
  "DO NOT SELF-MEDICATE",
];

Font.register({
  family: "Comfortaa",
  src: comfortaa,
});

Font.register({
  family: "Trebuchet",
  src: trebuchet,
});

const Prescription = ({ doctor, customerData, medicalHistory, provisionalDiagnosis, howToUse }) => {
  return (
    <Document
      language="en"
      title="Calmosis Prescription"
      author={`Dr. ${doctor.name}`}
      /* onRender={({ blob }) => {
        if (!blob) return;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.addEventListener("load", () => {
          // setDownloadLink(reader.result);
        });
      }} */
    >
      <Page size="A4" style={styles.page}>
        <View style={{ height: 16, backgroundColor }} />

        <View style={styles.doctorSection}>
          <Image src={logo} style={{ maxWidth: 132, marginTop: 4, marginLeft: 4 }} />
          <View style={{ display: "flex", gap: 20 }}>
            <View style={{ display: "flex", alignItems: "flex-end", color: "#303934" }}>
              <Text style={{ fontSize: 20, textTransform: "capitalize" }}>Dr. {doctor.name}</Text>
              <Text style={{ alignSelf: "flex-end" }}>{doctor.qualification || 'BHMS'}</Text>
              {/* <Text style={{ alignSelf: "flex-end" }}>BHMS</Text> */}
            </View>
            <View style={{ fontSize: 14, display: "flex", alignItems: "flex-end" }}>
              <Text style={{ textDecoration: "underline" }}>Medical Registration Number</Text>
              <Text style={{ textDecoration: "underline" }}>{doctor.licenseNumber}</Text>
            </View>
          </View>
        </View>

        <View style={styles.customerSection}>
          <View style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ textTransform: "capitalize" }}>{customerData.address.name}</Text>
            <Text style={{ fontSize: 14, textTransform: "capitalize" }}>
              {customerData.address.age} Years, {customerData.address.gender}
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column", fontSize: 14, alignItems: "flex-end" }}>
            <Text>{dayjs(customerData.createdAt).format("DD/MMM/YY hh:mmA")}</Text>
            <Text>Prescription Id: {parseInt(customerData._id.slice(-6), 16)}</Text>
          </View>
        </View>

        <View style={{ height: 1, backgroundColor: "white" }} />

        <View style={styles.medicalSection}>
          <View style={{ marginTop: 8, marginBottom: 8, display: "flex", gap: 4 }}>
            <Text style={{ textTransform: "uppercase", color: grey }}>Medicine Details</Text>
            <Text style={{ fontSize: 15 }}>
              {(() => {
                const itemNames = customerData.lineItems.map((i) => i.name);
                return itemNames.length === 1 ? itemNames[0] : `${itemNames.slice(0, -1).join(", ")} and ${itemNames.slice(-1)[0]}`;
              })()}
            </Text>
          </View>
          <View style={{ marginTop: 8, marginBottom: 8, display: "flex", gap: 4 }}>
            <Text style={{ textTransform: "uppercase", color: grey }}>Medical History</Text>
            <Text style={{ fontSize: 15 }}>{medicalHistory || "NA"}</Text>
          </View>
          <View style={{ marginTop: 8, marginBottom: 8, display: "flex", gap: 4 }}>
            <Text style={{ textTransform: "uppercase", color: grey }}>Provisional Diagnosis</Text>
            <Text style={{ fontSize: 15 }}>{provisionalDiagnosis || "NA"}</Text>
          </View>
          <View style={{ marginTop: 8, marginBottom: 8, display: "flex", gap: 4 }}>
            <Text style={{ textTransform: "uppercase", color: grey }}>How to use</Text>
            <Text style={{ fontSize: 15 }}>{howToUse || "NA"}</Text>
          </View>
        </View>

        <View style={{ height: 1, backgroundColor: "white" }} />

        <View style={styles.medicalSection}>
          <View style={{ marginBottom: 6, display: "flex", gap: 4 }}>
            <Text style={{ textTransform: "uppercase", color: grey }}>Not recommended for</Text>
          </View>

          {notRecommendedFor.map((text, index) => (
            <Text key={index} style={{ fontSize: 12 }}>
              + {text}
            </Text>
          ))}
        </View>

        <View style={{ height: 1, backgroundColor: "white" }} />

        <View style={styles.medicalSection}>
          <Text style={{ textTransform: "uppercase", color: grey }}>Medical General Ad</Text>

          {general.map(({ title, text }, index) => (
            <View key={index} style={{ marginTop: 6, marginBottom: 6, display: "flex", gap: 4 }}>
              <Text>+ {title}</Text>
              <Text style={{ fontSize: 12 }}>{text}</Text>
            </View>
          ))}

          <Text style={{ textTransform: "uppercase", color: grey }}>Note</Text>

          {notes.map((text, index) => (
            <Text key={index} style={{ fontSize: 12 }}>
              + {text}
            </Text>
          ))}
        </View>

        <Text style={styles.disclaimerSection}>
          Disclaimer. This prescription is based on the information provided by you in an online consultation and not on any physical verification. Visit a doctor in case of
          emergency.
        </Text>

        <View style={{ height: 16, backgroundColor }} />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    color: "white",
    backgroundColor,
    fontFamily: "Trebuchet",
    flexDirection: "column",
  },
  doctorSection: {
    color: "black",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "#f1d100",
    justifyContent: "space-between",
    padding: 12,
  },
  customerSection: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "Comfortaa",
    backgroundColor,
  },
  medicalSection: {
    backgroundColor,
    fontSize: 15,
    padding: 16,
    gap: 2,
  },
  disclaimerSection: {
    backgroundColor: "#f1d100",
    padding: "16px 32px",
    textAlign: "center",
    color: "black",
    fontSize: 12,
  },
});

export default Prescription;

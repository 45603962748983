import Footer from "../../Basic/footer";
import Navbar from "../../Basic/navbar";
import React from "react";

const Terms = () => {
  return (
    <main className="block">
      <Navbar />
      <div className="content">
        <h2 className="out">Terms and Conditions</h2>

        <div className="one">
          <p>
            <span>
              Please read these Terms and Conditions ("Terms") carefully before
              using the Calmosis website (the "Service") operated by Calmosis
              ("us," "we," or "our").
            </span>
            <span>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users, and others who access or use the Service.
            </span>
            <span>
              <b>
                {" "}
                By accessing or using the Service, you agree to be bound by
                these Terms. If you disagree with any part of the terms, then
                you may not access the Service.
              </b>
            </span>
            <br />
          </p>
          <p>
            <span>
              <b> 1) Use of the Service</b> <br />
              <br />
              1.1. Eligibility: You must be at least 18 years old to use this
              Service. By using the Service, you represent and warrant that you
              are at least 18 years old
              <br />
              1.2. Accuracy of Information: You agree to provide accurate,
              current, and complete information when using the Service. We
              reserve the right to suspend or terminate your account if any
              information provided is inaccurate, outdated, or incomplete.
            </span>
            <br />
          </p>
          <p>
            <span>
              <b>2) Content</b> <br />
              <br />
              2.1. Intellectual Property: The content on the Service, including
              text, graphics, logos, images, audio clips, digital downloads, and
              data compilations, is the property of Calmosis or its content
              suppliers and protected by international copyright laws.
              <br />
              2.2. User-Generated Content: By submitting or posting content on
              the Service, you grant Calmosis a non-exclusive, royalty-free,
              worldwide, and perpetual license to use, reproduce, modify, adapt,
              publish, translate, distribute, and display such content.
            </span>
            <br />
          </p>
          <p>
            <span>
              <b> 3) Privacy Policy</b> <br />
              <br />
              Your use of the Service is also governed by our Privacy Policy,
              which can be found at{" "}
              <a href="/legal/privacy">www.calmosis.com/legal/privacy</a>. By
              using the Service, you consent to the terms of our Privacy Policy.
            </span>
            <br />
          </p>
          <p>
            <span>
              <b> 4) Terminatio</b>n <br />
              We reserve the right to terminate or suspend access to the Service
              immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach these
              Terms.
            </span>
            <br />
          </p>
          <p>
            <span>
              <b> 5) Changes to Terms</b> <br />
              We reserve the right to modify or replace these Terms at any time.
              Any changes will be effective immediately upon posting on the
              Service. Your continued use of the Service after the posting of
              any changes constitutes acceptance of those changes.
            </span>
            <br />
          </p>
          <p>
            <span>
              <b> 6) Dispute Resolution</b>
              <br />
              In the event that an amicable resolution cannot be reached, all
              disputes shall be subject to the exclusive jurisdiction of the
              High Court of Bangalore, India.
            </span>
          </p>
        </div>
      </div>

      <div className="line" />

      <Footer />
    </main>
  );
};

export default Terms;

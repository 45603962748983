import DashNav from "../../components/Layout/dash-nav";
import { useEffect, useState } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import { Button } from "antd";
import axios, { baseURL } from "../../utils/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useAuth } from "../../context/auth";
import BasicTable from "../../components/Layout/BasicTable";

function stringToArrayBuffer(byteString) {
  var byteArray = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.codePointAt(i);
  }
  return byteArray;
}

const DashboardPrescription = () => {
  const [auth] = useAuth();
  const [orders, setOrders] = useState([]);
  const [loadingPrescriptions, setLoadingPrescriptions] = useState(true);

  const headers = [
    {
      field: "dateTime",
      headerName: "Date & Time",
      minWidth: 240,
    },
    {
      field: "orderNumber",
      headerName: "Order #",
      minWidth: 240,
    },
    {
      field: "productInfo",
      headerName: "Product",
      minWidth: 240,
      sortable: false,
      renderCell: ({ value }) => (
        <ul>
          {value.map((p) => (
            <li key={p}>{p}</li>
          ))}
        </ul>
      ),
    },
    {
      field: "prescription",
      headerName: "Prescription",
      minWidth: 320,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell({ value }) {
        const downloadPrescription = async () => {
          const el = document.createElement("a");
          const blob = new Blob([stringToArrayBuffer(value)], { type: "application/pdf" });
          el.href = URL.createObjectURL(blob);
          el.download = "Prescription.pdf";
          document.body.appendChild(el);
          el.click();
          URL.revokeObjectURL(el.href);
          el.remove();
        };
        return (
          <Button className="rounded-sm w-48" onClick={downloadPrescription}>
            Download prescription
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/api/prescriptions/${auth.user._id}`);
        setOrders(
          data.map((o) => ({
            _id: o._id,
            dateTime: dayjs(o.createdAt).format("DD/MM/YY | hh:mmA"),
            orderNumber: o._id,
            productInfo: o.lineItems.map((l) => `${l.name} x${l.quantity}`),
            prescription: o.prescription,
          }))
        );
      } catch (error) {
        console.error("Error fetching orders:", error.message);
        toast.error("Failed loading orders!");
      } finally {
        setLoadingPrescriptions(false);
      }
    };

    fetchPrescriptions();
  }, [setLoadingPrescriptions, auth]);

  return (
    <main className="dashboard prescription" data-scroll-container id="home">
      <UserMenu />

      <div className="mainbar">
        <DashNav />

        <div className="line" />
        <div className="label">
          <div className="element-terry-francine center-itemsss">
            <p className="text-[30px]">Your Prescription</p>
            <p className="text-[14px] opacity-50">The prescription is valid for 6 month</p>
          </div>
        </div>
        <div className="w-full">
          <div className="line !mt-0" />

          {!orders.length ? (
            <div className="empty w-full flex items-center justify-center flex-col mt-8">
              {!loadingPrescriptions && <img src="/flower_cart.png" alt="" />}
              <p className="pt-4 text-white">{loadingPrescriptions ? "Loading prescriptions..." : "You don't have a prescription yet"}</p>
            </div>
          ) : (
            <div className="label !w-[100%] lg:!w-[90%]  lg:mx-[50px]">
              <BasicTable headers={headers} data={orders} />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default DashboardPrescription;

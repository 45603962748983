import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminMenu from "../../components/Layout/AdminMenu";
import BasicTable from "../../components/Layout/BasicTable";
import DashNav from "../../components/Layout/dash-nav";
import axios from "../../utils/axios";

const ac = new AbortController();

const CreateCoupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const [discValue, setDiscValue] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [oneTimeOnly, setOneTimeOnly] = useState(false);

  const getCoupons = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/coupons", { signal: ac.signal });
      setCoupons(data);
    } catch (err) {
      console.error("getCoupons:", err);
      if (err instanceof AxiosError) {
        toast.error(err.response.data.error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCoupons();
    return () => ac.abort();
  }, [getCoupons]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 900);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCreate = async (e) => {
    e.preventDefault();

    if (!couponType) return toast.error("Please select a coupon type");

    try {
      const { data } = await axios.post("/api/coupons", {
        couponCode,
        couponType,
        discValue,
        oneTimeOnly,
      });
      setCoupons((c) => c.concat(data));
    } catch (error) {
      console.log("handleCreateCoupon:", error);
      if (error instanceof AxiosError) {
        toast.error(error.response.data.error);
      }
    }
  };

  const deleteCoupon = (id) => async () => {
    try {
      await axios.delete(`/api/coupons/${id}`);
      setCoupons((c) => c.filter((x) => x._id !== id));
    } catch (err) {
      console.error("Delete coupon:", err);
      if (err instanceof AxiosError) {
        toast.error(err.response.data.error);
      }
    }
  };

  const headers = [
    {
      field: "code",
      headerName: "Coupon Code",
      width: 240,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Coupon Type",
      width: 240,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Discount Value",
      width: 240,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "oneTimeOnly",
      headerName: "Is single use",
      width: 240,
      headerAlign: "center",
      align: "center",
      renderCell({ value }) {
        return <span>{value ? "Yes" : "No"}</span>;
      },
    },
    {
      width: 240,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell({ id }) {
        return (
          <button type="button" className="btn btn-primary p-1.5" onClick={deleteCoupon(id)}>
            Delete
          </button>
        );
      },
    },
  ];

  return (
    <main className="dashboard account" data-scroll-container id="home">
      <AdminMenu />
      <div className="container-fluid mainbar m-3 p-3">
        <DashNav />
        <div className="login">
          <div className="content">
            {loading ? (
              "Loading..."
            ) : (
              <>
                <form>
                  <h1>Existing Coupons</h1>

                  <hr />

                  {!coupons.length ? <h1>No coupons available!</h1> : <BasicTable headers={headers} data={coupons} isMobile={isMobile} />}
                </form>

                <form onSubmit={handleCreate}>
                  <h1>Add Coupon</h1>

                  <hr />

                  <div className="m-1 w-75">
                    <div className="mb-3 flex flex-col gap-3">
                      <label htmlFor="couponType">Coupon Type</label>
                      <select name="couponType" value={couponType} onChange={(e) => setCouponType(e.target.value)} className="p-2 text-black">
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                      </select>
                    </div>

                    <div className="mb-3 flex flex-col gap-3">
                      <label htmlFor="oneTimeOnly">Single use per customer</label>
                      <select name="oneTimeOnly" value={oneTimeOnly} onChange={(e) => setOneTimeOnly(e.target.value)} className="p-2 text-black">
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="couponCode">Coupon Code</label>
                      <input
                        type="text"
                        required
                        name="couponCode"
                        value={couponCode}
                        placeholder="e.g. NEWYEAR15"
                        className="form-control"
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="discValue">Discount Value</label>
                      <input
                        type="number"
                        required
                        name="discValue"
                        value={discValue}
                        placeholder="e.g. 15"
                        min={0}
                        max={couponType === "percentage" ? 100 : 4500 /* We have all products at same price as of now */}
                        className="form-control"
                        onChange={(e) => setDiscValue(e.target.value)}
                      />
                    </div>

                    <button className="btn btn-primary p-2">Add Coupon</button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateCoupon;

import React from "react";
import { FiInstagram } from "react-icons/fi";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="p-2" style={{ marginTop: "20px" }}>
      <div className="topwrapper">
        <p className="xs:!text-[8px] middle ">
          All our products are recognized and approved by the state AYUSH
          Department, Madhya Pradesh, India.
        </p>
        <ul className="flex socials">
          <li>
            <a href="https://www.facebook.com/profile.php?id=61552363868158">
              <FaFacebook color="white" />
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/calmosis.official/">
              <FiInstagram color="white" />
            </a>
          </li>

          <li>
            <a href="https://www.youtube.com/channel/UCjA4zYzm__jA_GiGMjZZsXA">
              <FaYoutube color="white" />
            </a>
          </li>
        </ul>
      </div>
      <div className="table">
        {/* <div className="row">
          <div className="address">
            <p className="w-[250px]">
              <span
                style={{
                  color: "white",
                  fontSize: "20px",
                }}
              >
                Headquarters
              </span>
              <br />
              <span style={{ paddingTop: "5px" }}>
                Unispace business center, Whitefield, EPIP Zone, Bengaluru,
                Karnataka 560066
              </span>
            </p>
          </div>
        </div> */}

        <div className="row">
          <p className="sm:w-[300px]">
            <span
              style={{
                color: "white",
                fontSize: "20px",
              }}
            >
              Contact US
            </span>
            <br />
            <div className="flex flex-col g-4">
              <span>
                <span style={{ color: "#fff" }}>HQ: </span>    Unispace business center, Whitefield, EPIP Zone, Bengaluru,
                Karnataka 560066
              </span>
              <span style={{ paddingTop: "5px" }}>
                <p>
                  <a href="mailto: calm@calmosis.com">
                    Email : calm@calmosis.com
                  </a>
                </p>

                <p>
                  <a href="tel:+917090890890">Tel : +91-7090-890-890 </a>
                  (9am - 6pm)
                </p>
              </span>
            </div>
          </p>
        </div>

        <div className="row">
          <p className="sm:w-[250px]">
            <span
              style={{
                color: "white",
                fontSize: "20px",
              }}
            >
              Menu
            </span>
            <br />
            <ul>
              <li>
                <p>
                  <a href="/">Home</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/shop">Shop</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/about">About</a>
                </p>
              </li>
              <li>
                <p>
                  <a href="/legal/faq">FAQ</a>
                </p>
              </li>
              <li>
                <p>{/* <a href="/blog">Blog</a> */}</p>
              </li>
            </ul>
          </p>
        </div>

        <div className="row">
          <div className="policy">
            <span
              className=""
              style={{
                color: "white",
                opacity: "1",
                fontSize: "20px",
              }}
            >
              Policy
            </span>
            <br />
            <div className="">
              <ul>
                <li>
                  <p>
                    <a href="/legal/shipping">Shipping</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="/legal/refund">Refund and Returns</a>
                  </p>
                </li>
                {/* <li>
                  <p>
                    <a href="/legal/store">Store Policy</a>
                  </p>
                </li> */}
                <li>
                  <p>
                    <a href="/legal/terms">Terms and Conditions</a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="/legal/privacy">Privacy Policy</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-socials">
        <ul className="flex socials">
          <li>
            <a href="https://www.facebook.com/profile.php?id=61552363868158">
              <FaFacebook color="white" />
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/calmosis.official/">
              <FiInstagram color="white" />
            </a>
          </li>

          <li>
            <a href="https://www.youtube.com/channel/UCjA4zYzm__jA_GiGMjZZsXA">
              <FaYoutube color="white" />
            </a>
          </li>
        </ul>
      </div>

      <div className="bottom">
        <p className="md:flex  hidden">
          © 2023 by Calmosis Vedic Wellness Private Limited. All Rights
          Reserved.
        </p>
        <img
          className="md:h-full h-[8vh] relative top-[-2vh]"
          src="/white_name.svg"
          alt=""
        />
        <p className="md:hidden justify-center items-center text-center text-[8px] flex">
          © 2023 by Calmosis Vedic Wellness Private Limited. All Rights
          Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
